import React from "react"
import aplus from "../img/comptialogos/aplus.png"
import netplus from "../img/comptialogos/netplus.png"
import secplus from "../img/comptialogos/secplus.png"
import cloudplus from "../img/comptialogos/cloudplus.png" //change these to cloud and project icons
import projectplus from "../img/comptialogos/secplus.png"

import linux from "../img/linuxessentials.png"

import awscp from "../img/awslogos/awscp.png"
import awsai from "../img/awslogos/awsai.png"
import awsdev from "../img/awslogos/awsdev.png"
import awssolarc from "../img/awslogos/awssolarc.png"
import awssysops from "../img/awslogos/awssysops.png"

import azure from "../img/azure.png"

export const Certs = ({divclass}) => {
    var divclassname = "certbox " + divclass;
    
    var sizeadjust = {marginBottom: "-20px"}

    return (
            <div class={divclassname}>
                <h3>Certifications</h3>
                <h4>CompTIA:</h4>
                <div className="certbar">
                    <img src={aplus}/>
                    <img src={netplus}/>
                    <img src={secplus}/>
                    <img src={cloudplus}/>
                    <img src={projectplus}/>
                </div>

                <h4>AWS:</h4>
                <div className="certbar">
                    <img src={awscp} style={sizeadjust}/>
                    {/* <img src={awsai} style={sizeadjust}/>
                    <img src={awsdev} style={sizeadjust}/>
                    <img src={awssolarc}/>
                    <img src={awssysops}/> */}
                </div>

                {/* <h4>Azure:</h4>
                <div className="certbar">
                    <img src={azure}/>
                </div> */}

                <h4>LPI:</h4>
                <div className="certbar">
                    <img src={linux}/>
                </div>
            </div>
    );
};